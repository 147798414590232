import { template as template_6744c2344fe4487eb84531ceb39b2827 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6744c2344fe4487eb84531ceb39b2827(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
