import { template as template_2f21b114b4e1496c8d2707a8c1bf1b83 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import i18n from "discourse-common/helpers/i18n";
const UserSummarySection = template_2f21b114b4e1496c8d2707a8c1bf1b83(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
