import { template as template_5fae05835ceb47029fa7cd54e5cd8b6a } from "@ember/template-compiler";
const FKControlMenuContainer = template_5fae05835ceb47029fa7cd54e5cd8b6a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
