import { template as template_d4ffdfe229f54bfe9a023f9538bf2dfa } from "@ember/template-compiler";
const SidebarSectionMessage = template_d4ffdfe229f54bfe9a023f9538bf2dfa(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
